<template>
  <div>
    <span>
      <b>{{ title }}</b
      >: {{ humanSize(usage) }} de
      {{ humanSize(maxStorage) }}
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            x-small
            icon
            v-bind="attrs"
            v-on="on"
            class="ml-3"
            v-show="hover"
            @click="$emit('editAccount')"
          >
            <v-icon> {{ svg.mdiPencil }} </v-icon>
          </v-btn>
        </template>
        <span>Editar servidor</span>
      </v-tooltip>
      <span class="float-right text-right ml-6">{{ pct }}%</span>
    </span>
    <v-progress-linear class="mb-3" color="blue" :value="pct"></v-progress-linear>
  </div>
</template>

<script>
import { mdiPencil } from "@mdi/js";
import { humanReadableFileSize } from "vuetify/lib/util/helpers";

export default {
  props: {
    id: String,
    usage: Number,
    maxStorage: Number,
    pct: Number,
    hover: Boolean,
    title: String
  },
  data: function() {
    return {
      svg: {
        mdiPencil
      }
    };
  },
  methods: {
    humanSize: humanReadableFileSize
  }
};
</script>

<style scoped></style>
