var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"min-width":"330","outlined":"","elevation":hover ? 3 : 0,"transition":"expand"}},[(_vm.loading)?_c('v-progress-linear',{staticClass:"mx-auto",attrs:{"color":"green lighten-3","indeterminate":""}}):_vm._e(),(!_vm.loading)?[_c('v-list-item',[_c('img',{staticClass:"mr-3",attrs:{"height":"28px","src":_vm.iconUrl}}),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"subtitle-1"},[_vm._v(_vm._s(_vm.title))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.fileCount)+" arquivos")])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}],staticClass:"actions"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('editServer', _vm.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.svg.mdiPencil))])],1)]}}],null,true)},[_c('span',[_vm._v("Editar servidor")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('editAccount', null, _vm.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.svg.mdiPlaylistPlus))])],1)]}}],null,true)},[_c('span',[_vm._v("Adicionar conta")])])],1),(_vm.pct)?_c('span',{staticClass:"font-italic"},[_vm._v(_vm._s(_vm.pct)+"%")]):_vm._e()],1),_c('v-card-text',_vm._l((_vm.accounts),function(account){return _c('Account',_vm._b({key:account.id,staticClass:"mb-4",attrs:{"id":account._jv.id,"hover":hover},on:{"editAccount":function($event){return _vm.$emit('editAccount', account._jv.id, _vm.id)}}},'Account',account,false))}),1)]:_vm._e()],2)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }