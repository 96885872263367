import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import Login from "../views/Login.vue";
import Logout from "../views/Logout.vue";
import Upload from "../views/Upload.vue";
import MassRename from "../views/MassRename.vue";
import Servers from "../views/Servers";
import Failures from "../views/Failures";
import Transload from "../views/Transload";
import AccessDenied from "../views/AccessDenied";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Upload
  },
  /*{
    path: "/",
    component: Dashboard
  },*/
  {
    path: "/servers",
    name: "Servers",
    component: Servers
  },
  {
    path: "/remoteupload",
    name: "Transload",
    component: Transload
  },
  {
    path: "/massrename",
    component: MassRename
  },
  {
    path: "/failures",
    component: Failures
  },
  {
    path: "/files",
    name: "Files",
    component: () => import(/* webpackChunkName: "files" */ "../views/Files.vue")
  },
  { path: "/login", name: "Login", component: Login },
  { path: "/logout", name: "Logout", component: Logout },
  { path: "/403", name: "Access Denied", component: AccessDenied }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});
router.afterEach(() => {
  store.commit("deleteAlerts");
});
export default router;
