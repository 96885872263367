<template>
  <v-app>
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row justify="center">
          <h1>
            Saiu. Você será redirecionado.
          </h1>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  created() {
    setTimeout(() => this.$router.push({ path: "/login" }), 3000);
  }
};
</script>
