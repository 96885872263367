var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('LoadingLarge',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"color":"white"}}),_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],on:{"drop":function($event){$event.preventDefault();return _vm.addDropFile.apply(null, arguments)},"dragover":function($event){$event.preventDefault();_vm.overlay = true}}},[_c('v-overlay',{attrs:{"absolute":"","value":_vm.overlay}},[_c('v-icon',{attrs:{"size":"95"}},[_vm._v(" "+_vm._s(_vm.svg.mdiCloudUpload)+" ")])],1),_c('v-card-title',{staticClass:"text-h5 green white--text"},[(_vm.server.title)?_c('div',[_vm._v("Editar: "+_vm._s(_vm.server.title))]):_c('div',[_vm._v("Adicionar servidor")])]),_c('v-card-text',[_c('v-row',{staticClass:"px-4 pt-6"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"mb-0 pb-0",attrs:{"label":"Título","rules":[function (v) { return !!v || 'Título obrigatório'; }],"outlined":"","counter":""},model:{value:(_vm.server.title),callback:function ($$v) {_vm.$set(_vm.server, "title", $$v)},expression:"server.title"}}),_c('v-select',{staticClass:"mt-4 pt-0",attrs:{"label":"Tipo do servidor","rules":[function (v) { return !!v || 'Tipo obrigatório'; }],"items":_vm.types,"disabled":_vm.server.id,"item-text":"title"},model:{value:(_vm.server.plugin),callback:function ($$v) {_vm.$set(_vm.server, "plugin", $$v)},expression:"server.plugin"}}),_c('v-tooltip',{attrs:{"top":"","nudge-left":"30","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"mb-6"},'div',attrs,false),on),[_c('v-switch',{staticClass:"mt-2 pt-0",attrs:{"label":"Obrigatório","hide-details":"","inset":""},model:{value:(_vm.server.required),callback:function ($$v) {_vm.$set(_vm.server, "required", $$v)},expression:"server.required"}})],1)]}}])},[_c('span',[_vm._v("Terá uma cópia de todos os arquivos. Ex: para backups")])]),_c('v-tooltip',{attrs:{"top":"","nudge-left":"30","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-switch',{attrs:{"label":"Oculto","inset":"","hide-details":""},model:{value:(_vm.server.hidden),callback:function ($$v) {_vm.$set(_vm.server, "hidden", $$v)},expression:"server.hidden"}})],1)]}}])},[_c('span',[_vm._v(" Não aparece nas opções de upload. Ex: para backups ")])]),_c('v-tooltip',{attrs:{"top":"","nudge-right":"60","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mt-6",attrs:{"label":"Frequência em dias para verificação","type":"number","suffix":"dias","prepend-inner-icon":_vm.svg.mdiProgressCheck,"rules":[
                  function (v) { return !!v || 'Campo obrigatório'; },
                  function (v) { return (!!v && v >= 3) || "Frequência mínima: 3 dias"; }
                ]},model:{value:(_vm.checkFrequency),callback:function ($$v) {_vm.checkFrequency=$$v},expression:"checkFrequency"}},'v-text-field',attrs,false),on))]}}])},[_c('span',[_vm._v(" Mantenha este número alto (tipo 7), pois isso gera bastante carga no sistema, e pode fazer com o que o sistema seja bloqueado pelo serviço ")])]),_c('v-row',{staticClass:"mt-4"},[(_vm.server.iconUrl && !_vm.file)?_c('v-img',{attrs:{"src":_vm.server.iconUrl,"max-width":"80"}}):_vm._e(),_c('v-file-input',{ref:"icon",attrs:{"label":"Ícone","outlined":"","truncate-length":"35","hint":"PNG/SVG transparente. Máximo de 80px em qualquer dimensão","persistent-hint":"","rules":[
                function (v) { return !!v || !!this$1.server.iconUrl || 'Ícone é obrigatório'; },
                function (v) { return (!!v && v.size <= 200000) ||
                  !!this$1.server.iconUrl ||
                  'Máximo de 200kb permitido'; }
              ],"accept":_vm.fileType,"prepend-icon":_vm.svg.mdiImage},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":_vm.cancel}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"green darken-1 white--text","loading":_vm.loading,"type":"submit","disabled":!_vm.valid}},[_vm._v(" Salvar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }