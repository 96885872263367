<template>
  <v-hover v-slot="{ hover }">
    <v-card min-width="330" outlined :elevation="hover ? 3 : 0" transition="expand">
      <v-progress-linear
        class="mx-auto"
        color="green lighten-3"
        indeterminate
        v-if="loading"
      ></v-progress-linear>

      <template v-if="!loading">
        <v-list-item>
          <img class="mr-3" height="28px" :src="iconUrl" />
          <v-list-item-content>
            <v-list-item-title class="subtitle-1">{{ title }}</v-list-item-title>
            <v-list-item-subtitle>{{ fileCount }} arquivos</v-list-item-subtitle>
          </v-list-item-content>
          <div class="actions" v-show="hover">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click="$emit('editServer', id)">
                  <v-icon>{{ svg.mdiPencil }}</v-icon>
                </v-btn>
              </template>
              <span>Editar servidor</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click="$emit('editAccount', null, id)">
                  <v-icon>{{ svg.mdiPlaylistPlus }}</v-icon>
                </v-btn>
              </template>
              <span>Adicionar conta</span>
            </v-tooltip>
          </div>
          <span class="font-italic" v-if="pct">{{ pct }}%</span>
        </v-list-item>
        <v-card-text>
          <Account
            class="mb-4"
            v-for="account in accounts"
            :key="account.id"
            v-bind="account"
            :id="account._jv.id"
            :hover="hover"
            @editAccount="$emit('editAccount', account._jv.id, id)"
          />
        </v-card-text>
      </template>
    </v-card>
  </v-hover>
</template>

<script>
import { mdiPlaylistPlus, mdiPencil } from "@mdi/js";
import { humanReadableFileSize } from "vuetify/lib/util/helpers";
import { size } from "lodash";
import Account from "./Account";
export default {
  components: { Account },
  props: {
    id: String,
    title: String,
    iconUrl: String,
    fileCount: Number
  },
  data: function() {
    return {
      accounts: [],
      loading: true,
      pct: null,
      maxStorage: 0,
      svg: {
        mdiPlaylistPlus,
        mdiPencil
      }
    };
  },
  methods: {
    humanSize: humanReadableFileSize,
    editClicked: (e, type, id) => this.emitEdit(type, id),
    emitEdit: function(typeToEdit, idToEdit) {
      this.$emit("editClicked", typeToEdit, idToEdit, this.id);
    }
  },
  created() {
    this.$store.dispatch("jv/get", `servers/${this.id}/accounts`).then(data => {
      this.accounts = data;
      if (size(this.accounts)) {
        Object.values(this.accounts).forEach(account => {
          this.maxStorage += account.maxStorage;
          // TODO FIX
          account.pct = Math.floor((account.usage / account.maxStorage) * 100);
        });

        this.pct = Math.floor((this.usage / this.maxStorage) * 100);
      }

      this.loading = false;
    });
  }
};
</script>

<style scoped lang="scss"></style>
