<template>
  <div class="loading-spinner" :style="'--leaf-loading-color:' + color">
    <div class="clover">
      <div class="leaf leaf-a"></div>
      <div class="leaf leaf-b"></div>
      <div class="leaf leaf-c"></div>
      <div class="leaf leaf-d"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "#66b360"
    }
  }
};
</script>

<style lang="scss">
.loading-spinner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 100px;
  width: 100px;

  .clover {
    transform: translate3d(0, 0, 0);
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100px;
    width: 100px;
    margin: auto;
    animation: spin 2.5s ease infinite;

    .leaf {
      margin: 2%;
      box-sizing: border-box;
      height: 46%;
      width: 46%;
      box-shadow: inset 0 0 0 5px var(--leaf-loading-color);
      background-color: var(--leaf-loading-color);

      &.leaf-a {
        border-radius: 50% 50% 0 50%;
        animation: leaf-a-morph 2.5s ease infinite;
      }
      &.leaf-b {
        border-radius: 50% 50% 50% 0;
        animation: leaf-b-morph 2.5s ease infinite;
      }
      &.leaf-c {
        border-radius: 50% 0 50% 50%;
        animation: leaf-c-morph 2.5s ease infinite;
      }
      &.leaf-d {
        border-radius: 0 50% 50% 50%;
        animation: leaf-d-morph 2.5s ease infinite;
      }
    }
  }
}

@keyframes leaf-a-morph {
  0% {
    background-color: var(--leaf-loading-color);
  }
  20% {
    background-color: var(--leaf-loading-color);
  }
  25% {
    background-color: var(--leaf-loading-color);
  }
  45% {
    background-color: var(--leaf-loading-color);
  }
  50% {
    background-color: var(--leaf-loading-color);
  }
  70% {
    background-color: transparent;
  }
  75% {
    background-color: transparent;
  }
  95% {
    background-color: var(--leaf-loading-color);
  }
  100% {
    background-color: var(--leaf-loading-color);
  }
}

@keyframes leaf-b-morph {
  0% {
    background-color: var(--leaf-loading-color);
  }
  20% {
    background-color: var(--leaf-loading-color);
  }
  25% {
    background-color: var(--leaf-loading-color);
  }
  45% {
    background-color: transparent;
  }
  50% {
    background-color: transparent;
  }
  70% {
    background-color: var(--leaf-loading-color);
  }
  75% {
    background-color: var(--leaf-loading-color);
  }
  95% {
    background-color: var(--leaf-loading-color);
  }
  100% {
    background-color: var(--leaf-loading-color);
  }
}

@keyframes leaf-c-morph {
  0% {
    background-color: transparent;
  }
  20% {
    background-color: var(--leaf-loading-color);
  }
  25% {
    background-color: var(--leaf-loading-color);
  }
  45% {
    background-color: var(--leaf-loading-color);
  }
  50% {
    background-color: var(--leaf-loading-color);
  }
  70% {
    background-color: var(--leaf-loading-color);
  }
  75% {
    background-color: var(--leaf-loading-color);
  }
  95% {
    background-color: transparent;
  }
  100% {
    background-color: transparent;
  }
}

@keyframes leaf-d-morph {
  0% {
    background-color: var(--leaf-loading-color);
  }
  20% {
    background-color: transparent;
  }
  25% {
    background-color: transparent;
  }
  45% {
    background-color: var(--leaf-loading-color);
  }
  50% {
    background-color: var(--leaf-loading-color);
  }
  70% {
    background-color: var(--leaf-loading-color);
  }
  75% {
    background-color: var(--leaf-loading-color);
  }
  95% {
    background-color: var(--leaf-loading-color);
  }
  100% {
    background-color: var(--leaf-loading-color);
  }
}

@keyframes spin {
  0% {
    transform: rotateZ(0deg);
  }
  20% {
    transform: rotateZ(90deg);
  }
  25% {
    transform: rotateZ(90deg);
  }
  45% {
    transform: rotateZ(180deg);
  }
  50% {
    transform: rotateZ(180deg);
  }
  70% {
    transform: rotateZ(270deg);
  }
  75% {
    transform: rotateZ(270deg);
  }
  95% {
    transform: rotateZ(360deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
</style>
