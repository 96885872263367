var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('LoadingLarge',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadingInit),expression:"loadingInit"}],attrs:{"color":"white"}}),_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loadingInit),expression:"!loadingInit"}]},[_c('v-card-title',{staticClass:"text-h5 green white--text"},[(_vm.id)?_c('div',[_c('strong',[_vm._v(_vm._s(_vm.server.title))]),_vm._v(": Editar conta "),_c('i',[_vm._v(_vm._s(_vm.account.title))])]):_c('div',[_c('strong',[_vm._v(_vm._s(_vm.server.title)+" ")]),_vm._v(": Adicionar conta ")])]),_c('v-card-text',[_c('v-row',{staticClass:"px-4 pt-6"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Título","outlined":"","maxlength":"20","hide-details":"","rules":[
              function (v) { return !!v || 'Título obrigatório'; },
              function (v) { return (!!v && v.length >= 3 && v.length <= 20) ||
                'Mínimo de 3 e máximo de 20 caracteres.'; }
            ],"counter":"","required":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('img',{staticClass:"mr-3",attrs:{"height":"28px","src":_vm.server.iconUrl}})]},proxy:true}]),model:{value:(_vm.account.title),callback:function ($$v) {_vm.$set(_vm.account, "title", $$v)},expression:"account.title"}}),_c('v-tooltip',{attrs:{"top":"","nudge-right":"60","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"mb-6"},'div',attrs,false),on),[_c('v-switch',{attrs:{"label":"Ativada","inset":"","required":"","hide-details":""},model:{value:(_vm.account.status),callback:function ($$v) {_vm.$set(_vm.account, "status", $$v)},expression:"account.status"}})],1)]}}])},[_c('span',[_vm._v("Uma conta desativada não receberá novos arquivos, mas ainda é possível baixar arquivos")])]),_c('v-tooltip',{attrs:{"top":"","nudge-right":"60","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({on:{"mouseover":function($event){attrs.value = true}}},'div',attrs,false),on),[_c('v-slider',{attrs:{"tick-labels":['Padrão', 'Média', 'Alta', 'Máxima'],"max":3,"step":"1","ticks":"always","tick-size":"4"},model:{value:(_vm.account.priority),callback:function ($$v) {_vm.$set(_vm.account, "priority", $$v)},expression:"account.priority"}})],1)]}}])},[_c('span',[_vm._v("Prioridade maior significa que arquivos serão upados nesta conta primeiro, até acabar o espaço. Contas com a mesma prioridade serão balanceadas para sempre enviar o arquivo para a conta com mais espaço disponível.")])])],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"label":"Espaço total","prepend-inner-icon":_vm.svg.mdiDatabase,"type":"number","hint":'Mínimo: 10GB. Tamanho máximo de arquivo: ' + _vm.maxFilesizeHuman,"persistent-hint":"","suffix":"GB","rules":[
              function (v) { return !!v || 'Campo obrigatório'; },
              function (v) { return (!!v && v >= 10) || "Espaço mínimo: 10GB"; }
            ]},model:{value:(_vm.maxStorageHuman),callback:function ($$v) {_vm.maxStorageHuman=$$v},expression:"maxStorageHuman"}})],1)],1),_c('v-col',{attrs:{"cols":"8"}},[_vm._l((_vm.requiredTextfields),function(option){return _c('v-text-field',{key:option.value,attrs:{"label":option.value,"rules":[
            function (v) { return !!v || 'Campo obrigatório'; },
            function (v) { return (!!v && v.length <= option.max) || ("Tamanho máximo: " + (option.max) + " caracteres"); }
          ],"prepend-inner-icon":option.icon,"counter":option.max,"type":option.type},model:{value:(_vm.requiredOptObj[option.value]),callback:function ($$v) {_vm.$set(_vm.requiredOptObj, option.value, $$v)},expression:"requiredOptObj[option.value]"}})}),_vm._l((_vm.requiredBoolfields),function(option){return _c('v-switch',{key:option.value,attrs:{"label":option.value,"inset":"","hide-details":""},model:{value:(_vm.requiredOptObj[option.value]),callback:function ($$v) {_vm.$set(_vm.requiredOptObj, option.value, $$v)},expression:"requiredOptObj[option.value]"}})})],2)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":_vm.cancel}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"green darken-1 white--text","loading":_vm.loading,"type":"submit","disabled":!_vm.valid}},[_vm._v(" Salvar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }