<template>
  <v-navigation-drawer app :clipped="$vuetify.breakpoint.lgAndUp" v-model="drawer" color="#fafafa">
    <v-list dense>
      <template v-for="(item, index) in items">
        <v-divider v-if="item.divider" :key="index" dark class="my-4"></v-divider>
        <v-list-group
          v-if="item.children"
          :key="item.text"
          v-model="item.model"
          :prepend-icon="item.model ? item.icon : item.alticon"
          :append-icon="item.suffixicon"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item v-for="(child, i) in item.children" :key="i" :to="child.link" link>
            <v-list-item-action v-if="child.icon">
              <v-icon>{{ child.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ child.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item :disabled="item.disabled" v-else :key="item.text" :to="item.link" link>
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
    <template v-slot:append>
      <div class="pa-2">
        <v-btn to="/logout" block dark>
          Sair
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import {
  mdiFormatListBulleted,
  mdiCloudUpload,
  mdiUpload,
  mdiServerNetwork,
  mdiFormTextbox,
  mdiMenuDown,
  mdiAlert
} from "@mdi/js";

export default {
  computed: {
    drawer: {
      set(val) {
        this.$store.commit("setDrawer", val);
      },
      get() {
        return this.$store.state.drawer;
      }
    }
  },
  data() {
    return {
      svg: {
        chevronDown: mdiMenuDown
      },
      items: [
        {
          icon: mdiUpload,
          //alticon: mdiUpload,
          text: "Upload",
          link: "/",
          divider: true
        },
        {
          icon: mdiServerNetwork,
          text: "Gerenciar servidores",
          link: "/servers",
          disabled: !this.$keycloak.hasRealmRole("moderador")
        },
        { icon: mdiFormatListBulleted, text: "Últimos arquivos", link: "/files" },
        {
          icon: mdiCloudUpload,
          text: "Envio Remoto",
          link: "/remoteupload"
        },
        {
          icon: mdiAlert,
          text: "Falhas",
          link: "/failures"
        },
        {
          icon: mdiFormTextbox,
          text: "Renomear em massa",
          link: "/massrename",
          disabled: !this.$keycloak.hasRealmRole("moderador")
        }
      ]
    };
  }
};
</script>

<style></style>
