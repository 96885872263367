<template>
  <v-container fluid class="white--text text-center">
    <div style="font-size: 9.5vw; font-weight: bold">🔒 Erro 403<br /></div>
    <div style="font-size: 6vw">
      Acesso negado
    </div>
    <div style="font-size: 20px">
      Se tiver alguma dúvida, fale com um dos membros da equipe no Discord.
    </div>
  </v-container>
</template>

<script>
export default {
  name: "AccessDenied",
  mounted() {
    document.body.classList.add("red-bg");
  },
  destroyed() {
    document.body.classList.remove("red-bg");
  }
};
</script>

<style>
body.red-bg {
  background-color: #f6525d;
}
</style>
<style scoped>
.gigantic-font {
  font-weight: bold;
  font-size: 100px;
}
</style>
