var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(_vm.loading)?_c('LoadingLarge',{staticStyle:{"margin-bottom":"-50%"},attrs:{"color":"green"}}):_c('div',[_c('v-alert',{attrs:{"type":"info","outlined":""}},[_vm._v(" Antes de marcados como falhos: Uploads são tentados 3 vezes e Encodes são tentados 2 vezes. ")]),_c('v-card',[_c('v-card-title',[_vm._v(" Falhas "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"loading":_vm.loading,"items":_vm.items,"item-key":"_jv.id","items-per-page":20,"show-expand":"","expanded":_vm.expanded,"search":_vm.search},on:{"click:row":_vm.clicked},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_vm._v(_vm._s(item.message))])]}},{key:"item.localPath",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.localFile.localPath)+" ")]}},{key:"item.server",fn:function(ref){
var item = ref.item;
return [_c('img',{staticClass:"mr-2",attrs:{"height":"28px","src":item.account.server.iconUrl}}),_vm._v(" "+_vm._s(item.account.title)+" ")]}},{key:"item.failedAt",fn:function(ref){
var item = ref.item;
return [_c('timeago',{attrs:{"datetime":item.failedAt,"auto-update":60,"max-time":60}})]}},{key:"item.manage",fn:function(ref){
var item = ref.item;
return [(item.action === 'UploadJob')?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.retryUpload(item)}}},[_vm._v(" "+_vm._s(_vm.svg.retry)+" ")]):_vm._e(),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" "+_vm._s(_vm.svg.delete)+" ")])]}},{key:"no-data",fn:function(){return [_vm._v(" YAY! Nenhuma falha! ")]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }