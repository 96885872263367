<template>
  <v-container>
    <h2 class="title ma-4">Servidores</h2>
    <v-btn class="ma-3 success" @click="editServer()">+ Adicionar servidor</v-btn>
    <v-dialog v-if="dialog" v-model="dialog" persistent max-width="600px">
      <AccountForm v-if="editAcc" v-bind="editAcc" @done="closeDialog" />
      <ServerForm v-if="!editAcc" :id="editServerId" @done="closeDialog" />
    </v-dialog>

    <div class="d-flex flex-wrap">
      <Server
        class="ma-2"
        v-for="server in servers"
        :key="server._jv.id"
        :id="server._jv.id"
        v-bind="server"
        @editAccount="editAccount"
        @editServer="editServer"
      ></Server>
    </div>
  </v-container>
</template>

<script>
import { mdiImage } from "@mdi/js";
import Server from "../components/Server";
import ServerForm from "../components/ServerForm";
import AccountForm from "../components/AccountForm";
export default {
  components: { AccountForm, ServerForm, Server },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.editServerId = null;
      this.editAcc = null;
      // REFRESH;
    },
    editServer(id) {
      this.editServerId = id;
      this.dialog = true;
    },
    editAccount(id, serverId) {
      this.editAcc = {
        id: id || null,
        serverId: serverId || null
      };
      this.dialog = true;
    }
  },
  data: function() {
    return {
      editAcc: null,
      editServerId: null,
      svg: {
        mdiImage
      },
      dialog: false,
      servers: []
    };
  },
  created() {
    this.$store.dispatch("jv/get", "servers").then(data => {
      this.servers = Object.values(data);
    });
  }
};
</script>

<style scoped lang="scss">
.h2 {
}
</style>
