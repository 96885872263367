export default {
  install(Vue) {
    Vue.prototype.$fileUrl = (id, name, server = "") => {
      if (server) {
        id += "/";
      }
      return encodeURI(`${process.env.VUE_APP_API_URL}/${id}${server}/${name}`);
    };
  }
};
