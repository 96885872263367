<template>
  <v-container>
    <v-form ref="form" class="mt-5 ml-5" v-model="valid" @submit.prevent="valid && submit($event)">
      <v-text-field
        placeholder="https://meuservidor.com/arquivo.mkv"
        hint="Link do arquivo (<a target='_blank' href='https://api.alldebrid.com/v4/hosts/domains?agent=apiShowcase'>servidores suportados</a>) - ZippyShare, MegaNZ, Uptobox, etc"
        persistent-hint
        clearable
        full-width
        single-line
        solo
        rounded
        v-model="formInput"
        :rules="linkRules"
        :prepend-inner-icon="svg.link"
        type="url"
      >
        <template v-slot:append-outer>
          <v-btn color="green" text type="submit">
            + Adicionar
          </v-btn>
        </template>
        <template v-slot:message="{ message }">
          <span v-html="message"></span>
        </template>
      </v-text-field>
    </v-form>
    <v-card>
      <v-toolbar dense flat color="green lighten-1" dark>
        <v-toolbar-title>Servidores</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        Selecione servidores para onde enviar os arquivo salvos:
        <ServerSwitchList />
      </v-card-text>
    </v-card>
    <transition-group class="d-flex flex-wrap" name="slide" tag="div">
      <TransloadItem
        @newLink="addLink"
        @deleteLink="deleteLink"
        class="ma-3"
        v-for="link of links.slice().reverse()"
        :key="link"
        :url="link"
        min-width="300"
      >
      </TransloadItem>
    </transition-group>
    <CompletedLinks v-if="Object.keys(lPools).length" :link-pools="lPools"></CompletedLinks>
  </v-container>
</template>

<script>
import { mdiClose, mdiLink } from "@mdi/js";
import TransloadItem from "../components/TransloadItem";
import ServerSwitchList from "../components/ServerSwitchList";
import CompletedLinks from "../components/CompletedLinks";

export default {
  name: "Transload",
  components: { ServerSwitchList, TransloadItem, CompletedLinks },
  data() {
    return {
      lPools: {},
      links: [],
      linkRules: [
        v => (v || "").length <= 2000 || `Máximo de 2000 caracteres`,
        v => !!v || "Necessário.",
        v => (v || "").length >= 6 || `Mínimo de 6 caracteres`,
        v => !this.links.includes((v || "").trim()) || "Duplicado.",
        v => /^(https?):\/\/(-\.)?([^\s/?.#]+\.?)+(\/[^\s]*)?$/i.test(v) || `URL inválida`
      ],
      formInput: "",
      valid: null,
      svg: {
        clear: mdiClose,
        link: mdiLink
      }
    };
  },
  methods: {
    deleteLink(url) {
      const index = this.links.indexOf(url);
      if (index !== -1) this.links.splice(index, 1);
    },
    submit() {
      this.links.push(this.formInput.trim());
      this.$refs.form.reset();
    },
    addLink(servers) {
      // The new server is replacing the index of the old file in the old server. FIX!
      Object.entries(pools).forEach(([id, server]) => {
        const oldLinks = this.lPools?.[id]?.items;
        if (!oldLinks) {
          this.$set(this.lPools, id, server);
        } else {
          this.lPools[id].items.push(server.items[0]);
        }
      });
    }
  }
};
</script>

<style lang="scss">
.name-input {
  padding: 0;
  display: inline-block;

  input {
    color: #333;
    min-width: 300px;
    font-size: 0.9rem;
  }
}
</style>
<style lang="scss" scoped>
.v-card.white--text::v-deep {
  div,
  span {
    color: white;
  }
}
.slide-move {
  transition: transform 1s;
}
.slide-item {
  transition: all 1s;
  display: inline-block;
  margin-right: 10px;
}
.slide-enter,
.slide-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
.slide-leave-active {
  position: absolute;
}
</style>
