<template>
  <v-container ref="drop" @drop="dragging = false" @dragenter="dragging = true" class="pt-0">
    <v-overlay absolute :value="dragging" z-index="1">
      <v-icon size="100"> {{ svg.clover }} </v-icon>
    </v-overlay>
    <v-container class="pt-0">
      <v-card min-width="600">
        <v-toolbar dense flat color="green  lighten-1" dark>
          <v-toolbar-title>Servidores</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <ServerSwitchList
            style="text-align: center"
            @changeSelectedServers="changeSelectedServers"
          />
        </v-card-text>
      </v-card>
    </v-container>
    <v-container>
      <v-card min-width="600" min-height="100">
        <v-toolbar dense flat color="green lighten-1" dark>
          <v-toolbar-title>Uploads</v-toolbar-title>
          <v-card-actions>
            <v-btn depressed color="success darken-1" class="ml-3" @click="startUpload">
              <v-icon left dark> {{ svg.clover }} </v-icon>Iniciar
            </v-btn>
            <v-btn depressed color="success darken-1" class="ml-3" ref="selectBtn">
              <v-icon left dark> {{ svg.paperclip }} </v-icon>Selecionar...
            </v-btn>
            <v-btn depressed color="success darken-1" class="ml-3" @click="clearUploads">
              <v-icon left dark> {{ svg.close }} </v-icon>Limpar
            </v-btn>
          </v-card-actions>
        </v-toolbar>
        <v-simple-table dense style="min-height: 100px">
          <div style="text-align: center" class="ma-6" v-if="!uploadItems.length">
            <v-icon size="50"> {{ svg.paperclip }} </v-icon>
            <h3>Arraste ou selecione arquivos para começar...</h3>
          </div>
          <tbody class="link-table">
            <tr
              class="my-2"
              v-for="(item, index) in uploadItems"
              :key="item.uniqueIdentifier"
              v-bind:class="{ error: item.error }"
            >
              <td width="90">
                <v-btn
                  icon
                  heigth="28"
                  width="28"
                  v-show="item.isUploading() && !item.error"
                  @click="item.pause()"
                >
                  <v-icon color="grey lighten-1">
                    {{ svg.pause }}
                  </v-icon>
                </v-btn>
                <v-btn
                  icon
                  heigth="28"
                  width="28"
                  v-show="!item.isUploading() && !item.error"
                  @click="item.resume()"
                >
                  <v-icon color="grey lighten-1">
                    {{ svg.play }}
                  </v-icon>
                </v-btn>
                <v-btn icon heigth="28" width="28" v-show="item.error" @click="item.retry()">
                  <v-icon color="white">
                    {{ svg.refresh }}
                  </v-icon>
                </v-btn>
                <v-btn icon heigth="28" width="28" @click="remove(index)">
                  <v-icon v-bind:color="iconErrorColor(item.error)">
                    {{ svg.closeCircle }}
                  </v-icon>
                </v-btn>
              </td>
              <td class="pa-1">
                {{ item.name }}
                <div class="subtitle-2">
                  {{ humanReadableFileSize(item.size) }}
                  <span v-if="!item.error">- ETA: {{ item.eta }}</span>
                  <strong v-if="item.error"> - {{ item.message }} </strong>
                </div>
              </td>
              <td width="20%">
                <span class="d-inline-block">{{ item.pct }}%</span>
                <v-progress-linear
                  class="d-inline-block align-md-center"
                  color="green lighten-2"
                  :value="item.pct"
                ></v-progress-linear>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>
    </v-container>

    <v-container>
      <CompletedLinks ref="completedLinks" />
    </v-container>
  </v-container>
</template>
<script>
import Flow from "@flowjs/flow.js";
import { humanReadableFileSize } from "vuetify/lib/util/helpers";
import {
  mdiClover,
  mdiPaperclip,
  mdiClose,
  mdiPause,
  mdiPlay,
  mdiCloseCircle,
  mdiRefresh
} from "@mdi/js";
import ServerSwitchList from "../components/ServerSwitchList";
import CompletedLinks from "../components/CompletedLinks";
import Vue from "vue";

export default {
  data() {
    return {
      svg: {
        clover: mdiClover,
        paperclip: mdiPaperclip,
        close: mdiClose,
        pause: mdiPause,
        play: mdiPlay,
        closeCircle: mdiCloseCircle,
        refresh: mdiRefresh
      },
      dragging: false,
      dialog: false,
      flow: null,
      alerts: [],
      uploadItems: [],
      completedMessage: "",
      allowedTypes: ["x-matroska", "video/mp4"],
      iconErrorColor: v => (v ? "white" : "grey lighten-1")
    };
  },
  methods: {
    changeSelectedServers(value) {
      this.flow.opts.query.servers = value;
    },
    startUpload() {
      this.flow.upload();
    },
    humanReadableFileSize(bytes) {
      return humanReadableFileSize(bytes, true);
    },
    remove(index) {
      this.uploadItems[index].cancel();
      this.$delete(this.uploadItems, index);
    },
    clearUploads() {
      this.flow.cancel();
      //this.$delete(this, "uploadItems");
      this.uploadItems = [];
    },
    secondsToHms(seconds) {
      const d = Number(seconds);
      const h = Math.floor(d / 3600);
      // eslint-disable-next-line no-mixed-operators
      const m = Math.floor((d % 3600) / 60);
      const s = Math.floor((d % 3600) % 60);

      const hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
      const mDisplay = m > 0 ? m + (m === 1 ? " minute, " : " minutes, ") : "";
      const sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";
      return hDisplay + mDisplay + sDisplay;
    },
    fileAdded(file) {
      file.icon = "mdi-close-circle";
      file.pct = 0;
      file.eta = 0;
      // Check if file was already added
      for (const item of this.uploadItems) {
        if (item.name === file.name && item.size === file.size) {
          return false;
        }
      }

      // Mime Type Validation
      const valid = this.allowedTypes.some(v => file.getType().includes(v));
      if (!valid) {
        this.notify({ content: "Tipo de arquivo não permitido.", color: "error" });
        return false;
      }

      // File size validation
      const sizeMb = file.size / 1024 ** 2;
      if (sizeMb < 5) {
        this.notify({ content: "Arquivo muito pequeno. Tem algo errado?", color: "error" });
        return false;
      }

      // eslint-disable-next-line no-control-regex
      if (!/^[\x00-\x7F]*$/.test(file.name)) {
        this.notify({ content: "Nome do arquivo tem caracteres bizarros!", color: "error" });
        return false;
      }

      this.uploadItems.push(file);
      return true;
    }
  },
  mounted() {
    const { flow } = this;

    flow.assignBrowse(this.$refs.selectBtn.$el);
    flow.assignDrop(this.$refs.drop);
    flow.on("fileAdded", this.fileAdded);
    flow.on("fileSuccess", (file, message) => {
      this.$refs.completedLinks.fileSuccess(file, JSON.parse(message));
      const fileIndex = this.uploadItems.findIndex(
        item => item.uniqueIdentifier === file.uniqueIdentifier
      );
      this.$delete(this.uploadItems, fileIndex);
    });

    flow.on("error", (message, file, chunk) => {
      let msg = "";
      if (chunk.xhr.status === 0) {
        msg = "Erro desconhecido";
      }
      if (chunk.xhr.status === 413) {
        msg = "Pedaço do arquivo muito grante pro servidor. Por favor entre em contato com um dev";
      }
      file.message = `Erro ${chunk.xhr.status} - ${msg}`;
    });

    flow.on("fileProgress", file => {
      file.pct = Math.floor(file.progress() * 100);
      file.eta = this.secondsToHms(file.timeRemaining());
    });
  },
  beforeDestroy() {
    this.flow.off();
    this.flow.unAssignDrop(this.$refs.drop);
  },
  created() {
    this.flow = new Flow({
      target: `${this.axios.defaults.baseURL}upload`,
      query: {
        servers: this.$store.state.selectedServers
      },
      headers: {
        Authorization: `Bearer ${Vue.prototype.$keycloak.token}`
      },
      chunkSize: 15 * 1024 * 1024, // 15MB
      maxChunkRetries: 3,
      simultaneousUploads: 6,
      //chunkSize: 50 * 1024 * 1024, // 50MB
      //maxChunkRetries: 0,
      //simultaneousUploads: 1,
      chunkRetryInterval: 500,
      speedSmoothingFactor: 0.02,
      permanentErrors: [400, 401, 404, 409, 415, 500, 501]
    });
    if (!this.flow.support) {
      this.alerts.push({
        text: "Seu navegador não suporta uploads HTML5.",
        type: "error"
      });
    }
  },
  components: {
    CompletedLinks,
    ServerSwitchList
  }
};
</script>
<style lang="scss">
.v-card .white--text .v-list-item__subtitle {
  color: inherit;
}
#links {
  resize: none;
  width: 100%;
}
.link-table {
  .pct-panel {
    width: 300px;
  }
  .error {
    color: white;
    .subtitle-2 {
      color: inherit;
    }
  }
  .subtitle-2 {
    font-weight: normal;
    color: #666666;
  }

  tr {
    //color: #444;
    &:nth-child(even) {
      background-color: #f8f8f8;
    }
    &:hover {
      background-color: #eee;
    }
  }
  td {
    border-bottom: none !important;
  }
}
</style>
