<template>
  <v-app id="app">
    <header v-if="!fullPage">
      <v-progress-circular />
      <Navigation />
      <TopBar />
    </header>
    <v-main>
      <v-alert
        v-for="(alert, index) in alerts"
        :key="index"
        :type="alert.type"
        dismissible
        class="mx-4"
      >
        {{ alert.message }}</v-alert
      >
      <transition name="slidefade">
        <router-view :style="!fullPage ? 'position:absolute' : ''" />
      </transition>
      <Snackbar />
    </v-main>
  </v-app>
</template>

<script>
import Navigation from "./components/Navigation";
import TopBar from "./components/TopBar";
import Snackbar from "./components/Snackbar";

export default {
  components: { TopBar, Navigation, Snackbar },
  computed: {
    fullPage() {
      return ["Login", "Logout", "Access Denied"].indexOf(this.$route.name) !== -1;
    },
    alerts() {
      return this.$store.state.alerts;
    }
  }
};
</script>

<style lang="scss">
#app {
  background: inherit;
}

/*slidefade transition*/
.slidefade-enter-active,
.slidefade-leave-active {
  transition: transform 0.4s ease-out, opacity 0.5s ease-out;
}
.slidefade-enter {
  transform: translateX(-30vw);
  opacity: 0;
}
.slidefade-leave-to {
  transform: translateX(0);
  opacity: 0;
}
</style>
