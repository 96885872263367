<template>
  <v-form ref="form" v-model="valid" @submit.prevent="save">
    <LoadingLarge v-show="loadingInit" color="white" />
    <v-card v-show="!loadingInit">
      <v-card-title class="text-h5 green white--text">
        <div v-if="id">
          <strong>{{ server.title }}</strong
          >: Editar conta <i>{{ account.title }}</i>
        </div>
        <div v-else>
          <strong>{{ server.title }} </strong>: Adicionar conta
        </div>
      </v-card-title>
      <v-card-text>
        <v-row class="px-4 pt-6">
          <v-col cols="12">
            <v-text-field
              label="Título"
              outlined
              maxlength="20"
              hide-details
              v-model="account.title"
              :rules="[
                v => !!v || 'Título obrigatório',
                v =>
                  (!!v && v.length >= 3 && v.length <= 20) ||
                  'Mínimo de 3 e máximo de 20 caracteres.'
              ]"
              counter
              required
            >
              <template v-slot:prepend>
                <img class="mr-3" height="28px" :src="server.iconUrl" />
              </template>
            </v-text-field>
            <v-tooltip top nudge-right="60" max-width="400">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" class="mb-6">
                  <v-switch label="Ativada" v-model="account.status" inset required hide-details />
                </div>
              </template>
              <span
                >Uma conta desativada não receberá novos arquivos, mas ainda é possível baixar
                arquivos</span
              >
            </v-tooltip>
            <v-tooltip top nudge-right="60" max-width="400">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" @mouseover="attrs.value = true">
                  <v-slider
                    v-model="account.priority"
                    :tick-labels="['Padrão', 'Média', 'Alta', 'Máxima']"
                    :max="3"
                    step="1"
                    ticks="always"
                    tick-size="4"
                  ></v-slider>
                </div>
              </template>
              <span
                >Prioridade maior significa que arquivos serão upados nesta conta primeiro, até
                acabar o espaço. Contas com a mesma prioridade serão balanceadas para sempre enviar
                o arquivo para a conta com mais espaço disponível.</span
              >
            </v-tooltip>
          </v-col>
          <v-col cols="8">
            <v-text-field
              label="Espaço total"
              v-model="maxStorageHuman"
              :prepend-inner-icon="svg.mdiDatabase"
              type="number"
              :hint="'Mínimo: 10GB. Tamanho máximo de arquivo: ' + maxFilesizeHuman"
              persistent-hint
              suffix="GB"
              :rules="[
                v => !!v || 'Campo obrigatório',
                v => (!!v && v >= 10) || `Espaço mínimo: 10GB`
              ]"
            />
          </v-col>
        </v-row>
        <v-col cols="8">
          <v-text-field
            v-for="option in requiredTextfields"
            :key="option.value"
            v-model="requiredOptObj[option.value]"
            :label="option.value"
            :rules="[
              v => !!v || 'Campo obrigatório',
              v => (!!v && v.length <= option.max) || `Tamanho máximo: ${option.max} caracteres`
            ]"
            :prepend-inner-icon="option.icon"
            :counter="option.max"
            :type="option.type"
          />
          <v-switch
            v-for="option in requiredBoolfields"
            :key="option.value"
            :label="option.value"
            v-model="requiredOptObj[option.value]"
            inset
            hide-details
          />
        </v-col>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="cancel">
          Cancelar
        </v-btn>
        <v-btn
          color="green darken-1 white--text"
          :loading="loading"
          type="submit"
          :disabled="!valid"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { mdiDatabase, mdiProgressCheck, mdiAccount, mdiLock } from "@mdi/js";
import LoadingLarge from "./LoadingLarge";
import { humanReadableFileSize } from "vuetify/lib/util/helpers";
import { utils } from "jsonapi-vuex";

export default {
  components: { LoadingLarge },
  props: {
    id: String,
    serverId: String
  },
  data: function() {
    return {
      loading: false,
      loadingObj: true,
      loadingOptions: true,
      valid: false,
      maxStorageHuman: 10,
      maxFilesizeHuman: 500,
      // account: {
      //   maxStorage: 100 * 100 * 100 * 100 * 100 * 100, // 1TB
      //   maxFilesize: 1000 * 100 * 100 * 100, // 1GB
      //   title: "",
      //   status: true,
      //   priority: 0,
      //   checkFrequency: 7,
      //   options: {},
      // _jv: {
      //   type: "accounts"
      // }
      // },
      account: {
        title: "",
        status: true,
        maxStorage: null,
        maxFilesize: null,
        checkFrequency: 96,
        priority: 0,
        options: {},
        _jv: {
          type: "accounts",
          relationships: {
            server: {
              data: { type: "servers", id: this.serverId }
            }
          }
        }
      },
      server: {
        title: ""
      },
      requiredOptions: [],
      requiredOptObj: {},
      svg: {
        mdiDatabase,
        mdiProgressCheck
      }
    };
  },
  methods: {
    save() {
      this.loading = true;
      this.account.maxStorage = this.maxStorageHuman * 1024 * 1024 * 1024; // GB to B
      if (this.id) {
        Object.assign(this.account.options, this.requiredOptObj);
        this.$store
          .dispatch("jv/patch", this.account)
          .then(() => {
            this.$emit("done", true);
          })
          .finally(() => (this.loading = false));
        return;
      }
      this.$store
        .dispatch("jv/post", this.account)
        .then(() => {
          this.$emit("done", true);
        })
        .finally(() => (this.loading = false));
    },
    cancel() {
      this.$refs.form.reset();
      this.$emit("done");
    }
  },
  computed: {
    requiredTextfields: () => this.requiredOptions.filter(v => v.type === "text"),
    requiredBoolfields: () => this.requiredOptions.filter(v => v.type === "bool"),
    loadingInit: {
      get() {
        return this.loadingOptions || this.loadingObj;
      },
      set(v) {
        this.loadingObj = v;
      }
    }
  },
  created() {
    this.$store.dispatch("jv/get", `servers/${this.serverId}`).then(data => {
      this.server = data;
      this.axios.get("plugins").then(res => {
        const plugin = res.data.find(v => v.value === this.server.plugin);
        for (const item of plugin.requiredOptions) {
          const obj = {
            value: item,
            type: "text",
            icon: null,
            max: 30
          };
          if (["user", "key"].includes(item)) obj.icon = mdiAccount;
          if (["pass", "secret"].includes(item)) obj.icon = mdiLock;
          if (item === "credentials") obj.max = 2500;
          if (item === "endpoint") obj.max = 120;
          if (item.startsWith("is_")) obj.type = "bool";
          this.requiredOptions.push(obj);
          //this.$set(this.requiredOptObj, item, item);
        }
        this.maxFilesizeHuman = humanReadableFileSize(plugin.maxFilesize);
        this.loadingOptions = false;
      });
    });

    if (!this.id) {
      return;
    }
    this.$store.dispatch("jv/get", `accounts/${this.id}`).then(data => {
      //this.$set(this, 'account', data);
      //this.account = Object.assign({}, data);
      this.account = utils.deepCopy(data);
      Object.assign(this.requiredOptObj, data.options);
      //Object.assign(this.account, data);
      this.maxStorageHuman = data.maxStorage / 1024 / 1024 / 1024;
      this.$refs.form.validate();
      this.loadingObj = false;
    });
  }
};
</script>

<style scoped></style>
