import Vue from "vue";
import Vuex from "vuex";
//import axios from "axios";
import VuexPersistence from "vuex-persist";
import { jsonapiModule } from "jsonapi-vuex";

/*const settings = Object.assign({}, Vue.axios.defaults, {
  baseURL: process.env.VUE_APP_API_URL + "/api/" || "",
  headers: {
    "Content-Type": "application/vnd.api+json"
  }
});
const api = axios.create(settings);*/

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  filter: mutation => {
    return [
      //"setAuthenticated",
      "setLastAttemptedPage",
      "updateSearchValue",
      "setDrawer",
      "setSelectedServers"
    ].includes(mutation.type);
  }
});

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    searchValue: "",
    drawer: null,
    snackbar: {
      content: "",
      color: "",
      timeout: 4000
    },
    alerts: [],
    lastAttemptedPage: "/",
    authenticated: false,
    loading: true,
    selectedServers: ["1"] // Anbient
  },
  mutations: {
    setLoading(state, value) {
      state.loading = value;
    },
    setAuthenticated(state, value) {
      state.authenticated = value;
    },
    setLastAttemptedPage(state, value) {
      state.lastAttemptedPage = value;
    },
    updateSearchValue(state, value) {
      state.searchValue = value;
    },
    setDrawer(state, value) {
      state.drawer = value;
    },
    showNotification(state, payload) {
      state.snackbar = { ...state.snackbar, ...payload };
    },
    setAlert(state, payload) {
      state.alerts.push(payload);
    },
    deleteAlerts(state) {
      state.alerts = [];
    },
    setSelectedServers(state, value) {
      state.selectedServers = value;
    }
  },
  actions: {},
  modules: { jv: jsonapiModule(Vue.axios, { cleanPatch: true }) },
  plugins: [vuexLocal.plugin]
});
