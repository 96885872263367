<template>
  <v-card min-width="600">
    <v-toolbar dense flat color="green lighten-1" dark>
      <v-btn v-if="isDialog" icon dark @click="dialogClose">
        <v-icon> {{ svg.close }}</v-icon>
      </v-btn>
      <v-toolbar-title>Arquivos no servidor</v-toolbar-title>
      <v-card-actions ref="container">
        <v-btn depressed color="success darken-1" class="mr-3" @click="copyCompleted">
          <v-icon left> {{ svg.contentCopy }} </v-icon>Copiar
        </v-btn>
      </v-card-actions>
    </v-toolbar>
    <div style="text-align: center" class="ma-6" v-if="isEmpty(servers)">
      <h3>...</h3>
    </div>
    <v-tabs v-model="tab" color="green">
      <v-tab v-for="item in servers" :key="item.id">
        <img height="28px" :src="item.iconUrl" class="mr-2" />
        {{ item.title }}
      </v-tab>
      <v-tabs-items v-model="tab" ref="tabs">
        <v-tab-item v-for="item in servers" :key="item.id">
          <v-card flat>
            <v-card-text>
              <v-simple-table dense>
                <tbody class="link-table">
                  <tr v-for="item in item.items" :key="item.id" :class="{ error: item.error }">
                    <td>
                      {{ item.url }}
                      <div v-if="item.error">
                        {{ item.message }}
                      </div>
                      <v-icon class="no-select" v-show="item.pct >= 100" color="green" dense>
                        {{ svg.check }}
                      </v-icon>
                    </td>
                    <td width="20%" v-show="item.pct < 100" class="no-select">
                      <v-progress-linear
                        striped
                        rounded
                        class="d-inline-block align-md-center"
                        :color="item.pct ? 'green lighten-2' : 'grey lighten-1'"
                        :indeterminate="!item.pct"
                        :value="item.pct"
                      ></v-progress-linear>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </v-card>
</template>

<script>
import { mdiCheck, mdiContentCopy, mdiClose } from "@mdi/js";
import { mergeDeep } from "vuetify/lib/util/helpers";
import { isEmpty } from "lodash";

export default {
  props: {
    isDialog: Boolean,
    linkServers: Object
  },
  computed: {
    servers: {
      get() {
        if (!isEmpty(this.lServers)) {
          return this.lServers;
        }
        return this.linkServers;
      },
      set(v) {
        this.lServers = v;
      }
    }
  },
  data: function() {
    return {
      menu: false,
      lServers: {},
      svg: {
        contentCopy: mdiContentCopy,
        check: mdiCheck,
        close: mdiClose
      },
      tab: null
    };
  },
  methods: {
    isEmpty,
    wsConnect() {
      this.$echo
        .channel("upload_status")
        .listen("UploadProgress", e => {
          const file = this.getItem(e.id, e.serverId);
          if (!file) return;
          file.pct = Math.floor((e.bytes / file.size) * 100);
        })
        .listen("UploadStatus", e => {
          const file = this.getItem(e.id, e.serverId);
          console.log([file, e.id, e.serverId]);
          //const index = this.getItem(e.id, e.serverId, true);
          //const file = this.servers[e.serverId].items[index];
          if (!file) return;
          this.$set(file, "error", !e.success);
          //file.error = !e.success;
          if (!e.success) {
            console.log("Erro no servidor: " + e.message);
            this.$set(file, "message", e.message);
            //file.message = e.message;
            file.pct = 0;
          } else {
            file.pct = 100;
          }
        });
    },
    fileSuccess(file, data) {
      this.wsConnect();
      data.servers.forEach(server => {
        const newItem = {
          size: file.size,
          url: this.$fileUrl(data.id, data.name, server.id),
          pct: 0,
          id: data.id
        };
        const oldItem = this.getItem(data.id, server.id);
        if (oldItem) {
          Object.assign(oldItem, newItem);
        }
        if (this.servers?.[server.id]?.items) {
          this.servers[server.id].items.push(newItem);
        }
        if (!this.servers?.[server.id]) {
          const newServer = {
            items: [newItem],
            id: server.id,
            iconUrl: server.iconUrl
          };
          this.$set(this.lServers, server.id, mergeDeep(server, newServer));
        }
      });

      this.notify({
        content: `Arquivo upado: ${file.name}`,
        color: "success",
        timeout: 2000
      });
    },
    dialogClose() {
      this.$emit("dialogClose");
    },
    copyCompleted() {
      let links = "";
      const activeServer = this.$refs.tabs.$children[this.tab]?.$vnode?.data?.key;
      if (!activeServer) {
        this.notify({ content: "Nenhum link para copiar.", color: "error" });
        return;
      }
      this.servers[activeServer].items.forEach(item => {
        links += item.url + "\n";
      });
      let container = this.$refs.container;
      this.$copyText(links, container);
    },
    getItem(id, serverId, onlyIndex = false) {
      const index = this.servers?.[serverId]?.items.findIndex(item => item.id === id);
      if (onlyIndex) {
        return index;
      }
      if (Number.isInteger(index) && index > -1) {
        return this.servers[serverId].items[index];
      }
    }
  },
  destroyed() {
    this.$echo.leaveChannel("upload_status");
  },
  beforeMount() {
    this.wsConnect();
    console.log(this.linkServers);
  }
};
</script>

<style>
.no-select {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}
</style>
