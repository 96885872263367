<template>
  <v-snackbar v-model="snackbar" :timeout="timeout" :color="color" top>
    {{ message }}
    <v-btn color="white" text @click="snackbar = false">
      Fechar
    </v-btn>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      snackbar: false,
      message: "Mensagem qualquer",
      color: "red",
      timeout: 4000
    };
  },

  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "showNotification") {
        this.message = state.snackbar.content;
        this.color = state.snackbar.color;
        this.timeout = state.snackbar.timeout;
        this.snackbar = true;
      }
    });
  }
};
</script>

<style scoped></style>
