<template>
  <v-container id="inspire" class="fill-height" justify="center">
    <v-container v-if="isLoggedIn">
      <v-row justify="center" class="ma-4"
        >Já está logado! - <router-link to="/"> Ir para home </router-link></v-row
      >
      <v-row justify="center">
        <v-btn :href="logoutUrl" depressed color="red" class="white--text">
          Logout
        </v-btn>
      </v-row>
    </v-container>
    <v-main v-else>
      <v-row justify="center" class="ma-4">
        <h1>
          Aplicação protegida. Você precisa autenticar.
        </h1>
      </v-row>
      <v-row justify="center">
        <v-btn :href="loginUrl" depressed color="green" class="white--text">
          Autenticar com Anbient
        </v-btn>
      </v-row>
    </v-main>
  </v-container>
</template>

<script>
export default {
  data: function() {
    return {
      isLoggedIn: this.$store.state.authenticated,
      logoutUrl: `${process.env.VUE_APP_API_URL}/logout`,
      loginUrl: `${process.env.VUE_APP_AUTH_URL}${process.env.VUE_APP_URL}${this.$store.state.lastAttemptedPage}`
    };
  }
};
</script>
