<template>
  <v-container>
    <v-subheader class="headline">
      Renomear em massa
    </v-subheader>
    <v-alert border="top" colored-border type="info" elevation="1">
      Marque a opção "Regex" pra fazer substituições complexas, como:<br />
      <code>\[Fansub\] Elfen Lied (\d{1,2})_720p</code>
      por <code>Elfen_Lied_$1_Fansub-Anbient</code><br />
      Este é um site bom para testar regexes: <a href="https://regex101.com/">Regex101</a>
    </v-alert>
    <v-form>
      <v-switch v-model="isRegex" class="ml-3" label="Regex" />
      <v-container class="d-flex">
        <v-text-field
          :rules="[rules.required, rules.counter]"
          ref="sourceField"
          v-model="sourceValue"
          :loading="loading"
          maxlength="90"
          outlined
          label="Texto original"
          placeholder="_Anime_No_Sekai_"
        />
        <v-spacer />
        <v-text-field
          :rules="[rules.required, rules.counter]"
          ref="targetField"
          v-model="targetValue"
          :loading="loading"
          maxlength="90"
          outlined
          label="Substituir por:"
          placeholder="_ANSK_"
        />
        <v-spacer />
      </v-container>
    </v-form>
    <v-btn color="info" @click.stop="dialog = true">
      Renomear
    </v-btn>
    <v-dialog v-model="dialog" max-width="450">
      <v-card>
        <v-card-title class="headline">
          Renomear arquivos?
        </v-card-title>
        <v-card-text>
          Você REALMENTE deseja aplicar essas mudanças?
          <br />
          Essa ação é IRREVERSÍVEL.<br />
          Pense 3 vezes antes de aplicar.
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="error" text @click="dialog = false">
            Cancelar
          </v-btn>
          <v-btn color="green" text @click="applyRename">
            Renomear!
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col class="pr-0 col1">
        <v-subheader class="d-inline font-weight-bold">
          Original
        </v-subheader>
        <v-simple-table dense>
          <template v-slot:default>
            <tbody>
              <tr v-for="(item, index) in sourceItems" :key="index">
                <td>{{ item }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col class="pl-0">
        <v-subheader class="d-inline font-weight-bold">
          Previsão
        </v-subheader>
        <v-simple-table dense>
          <template v-slot:default>
            <tbody>
              <tr v-for="(item, index) in targetItems" :key="index">
                <td>{{ item }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { debounce } from "lodash";

export default {
  computed: {
    sourceValue: {
      get() {
        return this.requestData.value.search[0].key;
      },
      set(text) {
        this.requestData.value.search[0].key = text;
        if (this.$refs.sourceField.valid) {
          this.debounceFetch(this.requestData);
        }
      }
    },
    targetValue: {
      get() {
        return this.requestData.value.search[0].renameTo;
      },
      set(text) {
        this.requestData.value.search[0].renameTo = text;
        if (this.$refs.sourceField.valid && this.$refs.targetField.valid) {
          this.debounceFetch(this.requestData);
        }
      }
    },
    isRegex: {
      get() {
        return this.requestData.value.search[0].operator === "regex";
      },
      set(value) {
        this.requestData.value.search[0].operator = value ? "regex" : "contains";
        if (this.$refs.sourceField.valid && this.$refs.targetField.valid) {
          this.debounceFetch(this.requestData);
        }
      }
    }
  },
  methods: {
    applyRename() {
      this.dialog = false;
      const data = Object.assign({}, this.requestData);
      data.value.commitChanges = true;
      if (this.$refs.sourceField.valid && this.$refs.targetField.valid) {
        this.debounceFetch(data);
      }
    }
  },
  data: () => ({
    formValid: false,
    loading: false,
    dialog: false,
    requestData: {
      value: {
        requiresCounts: true,
        commitChanges: false,
        search: [
          {
            key: "",
            operator: "contains",
            fields: ["local_path"],
            renameTo: null
          }
        ]
      }
    },
    rules: {
      required: value => !!value || "Necessário.",
      counter: value => {
        if (value && value.length > 3) {
          return true;
        }
        return "Min 4 caracteres";
      }
    },
    debounceFetch: debounce(function upd(data) {
      this.loading = true;
      this.axios.post(`${process.env.VUE_APP_API_URL}/files/list`, data).then(res => {
        const items = res.data;
        this.sourceItems = [];
        this.targetItems = [];
        this.loading = false;
        items.result.forEach(item => {
          this.sourceItems.push(item.local_path);
          if ("local_path_renamed" in item) {
            this.targetItems.push(item.local_path_renamed);
          }
        });
      });
    }, 500),
    sourceItems: [],
    targetItems: []
  })
};
</script>

<style scoped lang="scss">
.col1 {
  border-right: 1px solid #dddddd;
  min-height: 500px;
  text-align: right;
}
tr:nth-child(odd) {
  background-color: #fcfcfc !important;
}
</style>
