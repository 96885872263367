<template>
  <v-card :class="{ error: error, 'white--text': error }">
    <v-card-subtitle class="pb-0">
      <v-icon small left> {{ svg.globe }}</v-icon>
      <a class="external-url" target="_blank" :href="url">{{ url }}</a>
    </v-card-subtitle>
    <v-tooltip v-if="duplicate" top>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          v-bind="attrs"
          v-on="on"
          color="warning"
          large
          style="position: absolute; right: 6px; top: 6px;"
        >
          {{ svg.error }}
        </v-icon>
      </template>
      <span>Arquivo já existe no servidor... Será RENOMEADO.</span>
    </v-tooltip>
    <v-card-text>
      <template v-if="error">
        <v-icon left> {{ svg.error }}</v-icon>
        Erro: {{ error.message }}
      </template>
      <template v-else-if="size">
        <div>
          <label>Nome:</label>
          <v-text-field
            ref="filename"
            v-model="filename"
            :disabled="disabled"
            class="name-input"
            :rules="nameRules"
            @keydown.enter="save"
            :style="'width: ' + (this.filename.length - 6) + 'ch'"
          >
            <template v-slot:prepend-inner>
              <v-icon small>
                {{ svg.edit }}
              </v-icon>
            </template>
          </v-text-field>
        </div>
        <span class="mr-7"><label>Tamanho:</label>{{ humanSize(size) }}</span>
        <span><label>Fonte:</label>{{ host }}</span>
      </template>
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="green"
        class="white--text"
        small
        elevation="1"
        v-if="pct >= 100 && !error"
        :disabled="disabled"
        @click="save"
      >
        <v-icon left> {{ svg.save }}</v-icon>
        Salvar
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        v-if="link"
        :href="link"
        target="_blank"
        fab
        x-small
        :color="error ? 'secondary' : 'primary'"
        elevation="1"
      >
        <v-icon>{{ svg.download }}</v-icon>
      </v-btn>
      <v-btn fab x-small :color="error ? 'secondary' : 'error'" elevation="1" @click="emitDelete">
        <v-icon>{{ svg.delete }}</v-icon>
      </v-btn>
    </v-card-actions>
    <template v-if="!error && pct < 100">
      <div class="rmt-status overline">{{ statusText }}</div>
      <v-progress-linear
        :indeterminate="bufferPct === 0"
        :stream="bufferPct > 0"
        height="5"
        :value="pct"
        :buffer-value="bufferPct"
        color="green"
        striped
      ></v-progress-linear>
    </template>
  </v-card>
</template>

<script>
import {
  mdiAlert,
  mdiContentSave,
  mdiDeleteOutline,
  mdiDownload,
  mdiEarth,
  mdiPencilOutline
} from "@mdi/js";
import { humanReadableFileSize } from "vuetify/lib/util/helpers";

export default {
  props: {
    url: String
  },
  name: "RemoteLink",
  mounted() {
    /*
    // TODO REFACTOR for API call grouping
    this.axios
      .post("remotelink", { urls: [this.url] })
      .then(res => {
        if (res.data.error) {
          throw res.data.error;
        }
        this.statusText = "Processando no servidor...";
        this.bufferPct = 1;
        if (res.data.data.filesize) {
          this.size = res.data.data.filesize;
          Object.assign(this, res.data.data);
        } else if (res.data.data.infos[0]) {
          Object.assign(this, res.data.data.infos[0]);
        }

        this.$echo
          .channel("transload_status")
          .listen("TransloadProgress", e => {
            if (e?.id !== this.url) {
              return;
            }
            if (e.bytes) {
              this.bufferPct = 100;
              this.pct = Math.floor((e.bytes / this.size) * 100);
              this.statusText = "Enviando... " + this.pct + "%";
            }
          })
          .listen("TransloadStatus", e => {
            if (e?.id !== this.url) {
              return;
            }
            const data = e?.data;
            Object.assign(this, data);
            if (e.status === "error") {
              return;
            }
            if (e.status === "success") {
              this.pct = 100;
              return;
            }

            console.log(e);
            // Delayed link.
            if (data.time_left) {
              if (data.progress) {
                this.bufferPct = data.progress;
              }

              // 10 = 100ms 4 = 250ms 2 = 500ms. Fórmula by Karollyne.
              // Colocando 300ms na espera pra dar um tempo extra tolerância.
              const pctAmount = (100 - this.bufferPct) / (4 * data.time_left);
              if (this.repeater) {
                clearInterval(this.repeater);
              }
              this.repeater = setInterval(() => {
                if (this.bufferPct < 100) {
                  this.bufferPct += pctAmount;
                } else if (this.repeater) {
                  clearInterval(this.repeater);
                }
              }, 300);
            }
          });
      })
      .catch(this.errDump);
    */
  },
  destroyed() {
    this.$echo.leaveChannel("transload_status");
  },
  data: function() {
    return {
      link: null, // Download Link
      disabled: false,
      filename: "",
      newName: null,
      size: 0,
      host: "",
      pct: 0,
      bufferPct: 0,
      error: false,
      repeater: null,
      statusText: "Obtendo informações...",
      duplicate: false,
      svg: {
        save: mdiContentSave,
        error: mdiAlert,
        globe: mdiEarth,
        delete: mdiDeleteOutline,
        edit: mdiPencilOutline,
        download: mdiDownload
      },
      nameRules: [
        v => (v || "").length <= 2000 || `Máximo de 200 caracteres`,
        v => !!v || "Necessário.",
        v => (v || "").length >= 6 || `Mínimo de 6 caracteres`
      ]
    };
  },
  methods: {
    humanSize: humanReadableFileSize,
    errDump(err) {
      console.log(err);
      this.error = err;
    },
    emitDelete() {
      this.$emit("deleteLink", this.url);
    },
    save() {
      this.disabled = true;
      this.bufferPct = 0;
      this.pct = 0;
      this.statusText = "Renomeando no servidor...";
      const sentPools = [...this.$store.state.selectedServers];
      this.axios
        .patch("file/" + this.local_id, { name: this.newName || this.filename })
        .then(() => {
          this.statusText = "Criando uploads...";
          this.axios
            .post("jobs", {
              ids: [this.local_id],
              pools: sentPools
            })
            .then(() => {
              this.axios
                .post("files/links", {
                  ids: [this.local_id],
                  extraPools: sentPools
                })
                .then(res => {
                  this.$emit("newLink", res.data);
                  this.emitDelete();
                })
                .catch(this.errDump);
            })
            .catch(this.errDump);
        })
        .catch(this.errDump);
    }
  }
};
</script>
<style lang="scss">
.rmt-status {
  margin: -16px 10px 0;
}
.v-application .external-url {
  color: inherit;
  text-decoration: inherit;
}
</style>
<style scoped>
label {
  font-weight: bold;
  margin-right: 10px;
}
</style>
