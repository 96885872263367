<template>
  <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app dense color="#66b360" dark>
    <v-app-bar-nav-icon @click.stop="toggleDrawer" />
    <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
      <router-link class="hidden-sm-and-down font-weight-bold" to="/">
        {{ title }}
      </router-link>
    </v-toolbar-title>
    <v-text-field
      filled
      dense
      rounded
      single-line
      hide-details
      outlined
      :prepend-inner-icon="svg.magnify"
      label="Buscar arquivo"
      class="hidden-sm-and-down"
      v-model="searchValue"
      @keydown.enter="debounceSearch.flush"
    />
    <v-spacer />
    <v-spacer />
    <v-spacer />
    <v-btn icon to="/logout">
      <v-icon>{{ svg.logout }}</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
import { debounce } from "lodash";
import { mdiMagnify, mdiLogout } from "@mdi/js";

export default {
  props: {},
  data: () => ({
    svg: {
      magnify: mdiMagnify,
      logout: mdiLogout
    },
    title: process.env.VUE_APP_TITLE,
    debounceSearch: debounce(function upd(text) {
      if (this.$route.path !== "/files") {
        this.$router.push("files");
      }
      this.$store.commit("updateSearchValue", text);
    }, 400)
  }),
  methods: {
    toggleDrawer() {
      this.$store.commit("setDrawer", !this.$store.state.drawer);
    }
  },
  computed: {
    searchValue: {
      get() {
        return this.$store.state.searchValue;
      },
      set(text) {
        this.debounceSearch(text);
      }
    }
  },

  watch: {}
};
</script>

<style>
.v-application .v-toolbar__title a {
  color: inherit;
  text-decoration: inherit;
}
.v-text-field--solo-inverted.v-input--is-focused > .v-input__control > .v-input__slot input {
  caret-color: auto;
}
</style>
